<template>
    <div
            class="P-error-container page-background-dark d-flex align-center justify-center flex-column"
    >
        <h3 class="primary--text">Error 403</h3>
        <p class="secondary--text">FORBIDDEN</p>
    </div>
</template>

<script>
    export default {
        name: "Error403"
    }
</script>

<style scoped lang="scss">
.P-error-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 94px);
    h3 {
        font-size: 46px;
        font-weight: 600;
    }
    p {
        font-size: 24px;
    }
}
</style>